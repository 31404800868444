import { CompoundButton } from "@fluentui/react-components";
import ApplicationList from "../data/ApplicationList";
import { app, authentication } from "@microsoft/teams-js";
import { useEffect, useRef } from "react";
import * as Msal from "@azure/msal-browser";
import {
  TeamsUserCredentialAuthConfig,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { TeamsFxContext } from "./Context";
import "./ApplicationHub.scss";
import { useLocation, useParams } from "react-router-dom";
import { Console } from "console";

// Define a functional component named "News"
const ApplicationHub: React.FC = () => {
  //logic and functionality
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  // Accessing the 'appurl' parameter
  const appUrl = decodeURIComponent(searchParams.get('appurl') ?? "");
  
const appsToShow = appUrl ? ApplicationList.filter(item => item.Url === appUrl) : ApplicationList;

  const token = useRef<string | null | undefined>(null);
  useEffect(() => {
    app.initialize().then(() => {
      authentication
        .getAuthToken()
        .then((tokenValue) => {
          token.current = tokenValue;
        });
    });
  }, []);

  const getAccessToken = async () => {
    if (token.current != null) {
      return token.current;
    } else {
      const newToken = await authentication.getAuthToken();
      token.current = newToken;
      return token.current;
    }
  };

  // Define an asynchronous function to fetch and set posts from the API.
  const openApplication = async (baseUrl: string) => {
    const token = await getAccessToken();
    const linkWithToken = baseUrl + "?token=" + token;
    window.open(linkWithToken, "_blank");
  };

  return (
    <div className="centered-container">
      <div className="button-list-container">
        {appsToShow.map((item, index) => (
          <div className="button-container">
          <CompoundButton
          className="button"
            key={index} // Make sure to provide a unique key for each item in the map function
            secondaryContent={item.ShortDescription}
            icon={item.Icon}
            onClick={() => openApplication(item.Url)}
            size={"large"}
          >
            {item.Name}
          </CompoundButton>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ApplicationHub;
