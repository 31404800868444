import { WindowBulletListFilled, ReceiptMoneyRegular } from "@fluentui/react-icons";
const ApplicationList = [
  {
    Name: "Seminarbörse",
    ShortDescription: "Offnet der Seminarbörse Anwendung",
    Icon: <WindowBulletListFilled />,
    Url: "https://example.com/item1",
  },
  {
    Name: "Referent Abrechnung",
    ShortDescription: "Offnet der Referent Abrechnung Anwendung",
    Icon: <ReceiptMoneyRegular />,
    Url: "https://example.com/item2",
  }
];

export default ApplicationList;
