import { Combobox, Field, Input, Option } from "@fluentui/react-components";
import { app, pages } from "@microsoft/teams-js";
import { useEffect, useRef, useState } from "react";
import ApplicationList from "../data/ApplicationList";
import { Exception } from "sass";
import "./ApplicationHubConfiguration.scss";

// Define a functional component named "News"
const ApplicationHubConfiguration: React.FC = () => {
  // Define a state variables to store data
  const [displayName, setDisplayName] = useState("");
  const [selectedAppUrl, setSelectedAppUrl] = useState<string>("");

  //will be necessary to work around an issue with the save handler
  const displayNameRef = useRef(displayName);
  const selectedAppUrlRef = useRef<string>(selectedAppUrl);

  const saveConfiguration = (saveEvent: pages.config.SaveEvent) => {
    const baseUrl =
      "https://" + window.location.hostname + ":" + window.location.port;
    pages.config
      .setConfig({
        //set tab name
        suggestedDisplayName: displayNameRef.current,
        entityId: selectedAppUrlRef.current, //set content url
        contentUrl:
          baseUrl +
          "/index.html#/applicationhub?appurl=" +
          encodeURIComponent(selectedAppUrlRef.current),
        websiteUrl:
          baseUrl +
          "/index.html#/applicationhub?appurl=" +
          encodeURIComponent(selectedAppUrlRef.current),
      })
      .then(() => {
        saveEvent.notifySuccess();
      });
  };

  // Use the useEffect hook to initialize teams sdk and load posts when the component mounts.
  useEffect(() => {
    //initialize ms teams sdk
    app.initialize().then(() => {
      //register save event handler
      pages.config.registerOnSaveHandler(saveConfiguration);
    });
  }, []);

  //do something when categoryId or displayName is changed
  useEffect(() => {
    const isValid = (selectedAppUrl.length > 0 && displayName.length > 0) == true;
    if (isValid) {
      displayNameRef.current = displayName;
      selectedAppUrlRef.current = selectedAppUrl;
    } //if data is valid and teams sdk is initialized, enable the save button
    if (app.isInitialized()) {
      pages.config.setValidityState(isValid);
    }
  }, [selectedAppUrl, displayName]);

  return (
    <div className="full-size">
      <h1>Configuration</h1>

      <Field
        label="Reiter Name"
        required
        validationMessage={
          displayName.length == 0 ? "Dies ist ein Pflichtfeld." : null
        }
      >
        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </Field>
      <Field
        label="Anwendung"
        required
        validationMessage={
          selectedAppUrl.length == 0 ? "Bitte wählen Sie eine Anwendung aus." : null
        }
      >
        <Combobox
          placeholder="Anwendung auswählen"
          onOptionSelect={(e, data) => {
            if (data.optionValue) {
              var newValue = data.optionValue;
              setSelectedAppUrl(newValue);
            } else {
              setSelectedAppUrl("");
            }
          }}
        >
          {ApplicationList.map((application) => (
            <Option key={application.Url} value={application.Url}>
              {application.Name}
            </Option>
          ))}
        </Combobox>
      </Field>
    </div>
  );
};
export default ApplicationHubConfiguration;
